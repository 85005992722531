var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        {
          staticClass: "search-attr",
          staticStyle: {
            width: "100%",
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center",
                "flex-wrap": "wrap"
              }
            },
            [
              _c("a-input", {
                staticStyle: { width: "130px", margin: "5px" },
                attrs: { allowClear: "", size: "small", placeholder: "订单号" },
                model: {
                  value: _vm.params.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "orderNo", $$v)
                  },
                  expression: "params.orderNo"
                }
              }),
              _c("a-input", {
                staticStyle: { width: "130px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "物流单号"
                },
                model: {
                  value: _vm.params.expressNo,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "expressNo", $$v)
                  },
                  expression: "params.expressNo"
                }
              }),
              _c("a-input", {
                staticStyle: { width: "120px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "商品编号/名称"
                },
                model: {
                  value: _vm.params.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "productName", $$v)
                  },
                  expression: "params.productName"
                }
              }),
              _c("a-input", {
                staticStyle: { width: "145px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "买家/uId/昵称/店铺"
                },
                model: {
                  value: _vm.params.buyerUserName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "buyerUserName", $$v)
                  },
                  expression: "params.buyerUserName"
                }
              }),
              _c("a-input", {
                staticStyle: { width: "145px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "卖家/uId/昵称/店铺"
                },
                model: {
                  value: _vm.params.sellerUserName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "sellerUserName", $$v)
                  },
                  expression: "params.sellerUserName"
                }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "订单状态"
                  },
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                _vm._l(_vm.orderState, function(item, key) {
                  return _c(
                    "a-select-option",
                    { key: key, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "退款状态"
                  },
                  model: {
                    value: _vm.params.refundStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "refundStatus", $$v)
                    },
                    expression: "params.refundStatus"
                  }
                },
                _vm._l(_vm.resetOrderState, function(item, key) {
                  return _c(
                    "a-select-option",
                    { key: key, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "订单类型"
                  },
                  model: {
                    value: _vm.params.orderKind,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "orderKind", $$v)
                    },
                    expression: "params.orderKind"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("一口价")
                  ]),
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("竞拍单")
                  ])
                ],
                1
              ),
              _c("a-range-picker", {
                staticStyle: { width: "200px", margin: "5px" },
                attrs: {
                  placeholder: ["订单开始日期", "订单结束日期"],
                  size: "small"
                },
                on: { change: _vm.searchPickerChange }
              }),
              _c("a-range-picker", {
                staticStyle: { width: "200px", margin: "5px" },
                attrs: {
                  placeholder: ["支付开始日期", "支付结束日期"],
                  size: "small"
                },
                on: { change: _vm.searchPayPickerChange }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "商品状态"
                  },
                  model: {
                    value: _vm.params.productReviewStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "productReviewStatus", $$v)
                    },
                    expression: "params.productReviewStatus"
                  }
                },
                _vm._l(_vm.auctionTypeText, function(item, key) {
                  return _c(
                    "a-select-option",
                    { key: key, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "200px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "评级类型"
                  },
                  model: {
                    value: _vm.params.serviceType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "serviceType", $$v)
                    },
                    expression: "params.serviceType"
                  }
                },
                _vm._l(_vm.serviceTypeList, function(item, key) {
                  return _c(
                    "a-select-option",
                    { key: key, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
              _c("a-range-picker", {
                staticStyle: { width: "200px", margin: "5px" },
                attrs: {
                  placeholder: ["截拍开始日期", "截拍结束日期"],
                  size: "small"
                },
                on: { change: _vm.searchEndAuctionPickerChange }
              }),
              _c(
                "a-checkbox",
                {
                  staticClass: "ml-20",
                  attrs: { checked: _vm.isSendProductTimeoutStatus },
                  on: { change: _vm.handleChangeShowSendProductTimeoutStatus }
                },
                [_vm._v("即将发货超时")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "a-dropdown",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "overlay",
                      fn: function() {
                        return [
                          _c(
                            "a-menu",
                            [
                              _c(
                                "a-menu-item",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.downloadExcel }
                                    },
                                    [_vm._v("拍卖统计")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-menu-item",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.handleShowDqTradeExcel }
                                    },
                                    [_vm._v("平台成交总览")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "a-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("平台数据统计")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c(
            "a-table",
            {
              attrs: {
                pagination: _vm.pagination,
                loading: _vm.loading,
                columns: _vm.columns,
                "data-source": _vm.data,
                rowKey: function(record, n) {
                  return n
                }
              },
              on: { change: _vm.changePage },
              scopedSlots: _vm._u([
                {
                  key: "itemProductNameSlot",
                  fn: function(row) {
                    return _c(
                      "div",
                      {
                        class: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToAuctionList(row)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(row.productName) + " "),
                        row.productReviewStatus
                          ? _c(
                              "span",
                              { class: _vm.classType[row.productReviewStatus] },
                              [
                                _vm._v(
                                  "【" +
                                    _vm._s(
                                      _vm.textType[row.productReviewStatus]
                                    ) +
                                    "】"
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }
                },
                {
                  key: "itemRateServiceStatusSlot",
                  fn: function(row) {
                    return _c("div", {}, [
                      _c(
                        "span",
                        {
                          class:
                            _vm.rateServiceStatusClassType[row.ratingStatus]
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.computedRateServiceStatusMapTypeText(
                                row.ratingStatus
                              ) || "未送评"
                            )
                          )
                        ]
                      )
                    ])
                  }
                },
                {
                  key: "itemStatusNameSlot",
                  fn: function(row) {
                    return _c("div", {}, [
                      _c("div", [_vm._v(_vm._s(row.statusName))]),
                      _vm.computedUserShowSynchronousOrder(row)
                        ? _c(
                            "div",
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确定要同步当前订单？？",
                                    "ok-text": "确认",
                                    "cancel-text": "取消"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.handleSynchronousPay(row)
                                    }
                                  }
                                },
                                [
                                  _c("a-button", { attrs: { size: "small" } }, [
                                    _vm._v("同步支付订单")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      row.status === 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "mt-10",
                                  attrs: { type: "danger", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleSynchronousUnderlinePay(
                                        row
                                      )
                                    }
                                  }
                                },
                                [_vm._v("线下支付同步")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "setup",
                  fn: function(row) {
                    return _c("div", {}, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("查看详情")]
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-left": "8px" },
                          on: {
                            click: function($event) {
                              return _vm.openModalSnapshot(row.orderProductId)
                            }
                          }
                        },
                        [_vm._v("快照")]
                      )
                    ])
                  }
                }
              ])
            },
            [
              _c("template", { slot: "price" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", { staticStyle: { width: "50px" } }, [
                      _vm._v("价格")
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("a-icon", {
                          style: _vm.params.sort == 1 ? "color:#1890ff" : "",
                          attrs: { type: "caret-up" },
                          on: {
                            click: function($event) {
                              return _vm.sortPrice(1)
                            }
                          }
                        }),
                        _c("a-icon", {
                          style: _vm.params.sort == -1 ? "color:#1890ff" : "",
                          attrs: { type: "caret-down" },
                          on: {
                            click: function($event) {
                              return _vm.sortPrice(-1)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 1200, centered: "", footer: null },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { style: "max-height:" + _vm.ModalHeight + "px;overflow:auto;" },
            [
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
                  _c(
                    "div",
                    {
                      staticClass: "box-box",
                      staticStyle: {
                        "margin-left": "40px",
                        display: "flex",
                        "justify-content": "flex-start",
                        "align-items": "center"
                      },
                      attrs: { align: "center" }
                    },
                    [
                      ![-10, -14].includes(_vm.orderProductInfoVo.refundStatus)
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.openRefundModal(
                                    _vm.orderProductInfoVo
                                  )
                                }
                              }
                            },
                            [_vm._v("发起退货退款申请 ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确认延长收货么?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.bidOrderDelay(
                                _vm.orderProductInfoVo.id
                              )
                            }
                          }
                        },
                        [
                          _vm.orderProductInfoVo.refundStatus == null ||
                          _vm.orderProductInfoVo.refundStatus == -12
                            ? _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary" }
                                },
                                [_vm._v("延长收货")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._l(_vm.tagDayList, function(tag, index) {
                        return _c(
                          "a-tag",
                          {
                            key: index,
                            staticClass: "cursor-pointer ml-20",
                            attrs: { color: tag.selected ? "green" : "" },
                            on: {
                              click: function($event) {
                                return _vm.handleChangeTag(tag.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(tag.name))]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单编号："),
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.orderNo))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("买家："),
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.buyerUserName))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("卖家："),
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.sellerUserName))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "商户订单号：" +
                        _vm._s(_vm.orderProductInfoVo.requestNo || "")
                    )
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "下单/截拍时间：" +
                        _vm._s(_vm.orderProductInfoVo.createTime || "")
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单类型："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderProductInfoVo.orderKind == 0
                            ? "竞拍单"
                            : "一口价"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单支付类型： "),
                    _c(
                      "span",
                      {
                        class:
                          _vm.payTypeListClassType[
                            _vm.orderProductInfoVo.payType
                          ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedPayTypeListMapTypeText(
                              _vm.orderProductInfoVo.payType
                            )
                          )
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单原价/中拍价："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.orderPrice) + "元")
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("买家手续费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(_vm.orderProductInfoVo.buyerServiceMoney) + "元"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("卖家手续费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(_vm.orderProductInfoVo.sellerServiceMoney) + "元"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("发货运费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.logisticsFee) + "元")
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("冻结保证金："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(_vm.orderProductInfoVo.deductDepositMoney || 0) +
                          "元"
                      )
                    ]),
                    _vm._v("（"),
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.depositReleaseDesc))
                    ]),
                    _vm._v("）")
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单总金额："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.orderProductInfoVo.orderTotalPrice || 0) +
                            "元"
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _vm.ratingInfoVo
                ? _c("div", { staticClass: "modal-items mt-20" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("评级信息")]),
                    _c("div", { staticClass: "modal-content" }, [
                      _c("div", { staticClass: "basic-item" }, [
                        _vm._v("评级费："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.ratingInfoVo.ratingMoney || 0) + "元"
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "basic-item" },
                        [
                          _vm._v("标版费： "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.ratingInfoVo.biaobanMoney || 0) +
                                  "元"
                              )
                            ]),
                            _vm.ratingInfoVo.biaobanPayStatus === 6
                              ? _c("span", { staticClass: "color-gary" }, [
                                  _vm._v("（已退回）")
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定退回标版费?",
                                "ok-text": "Yes",
                                "cancel-text": "No",
                                okType: "danger"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleReturnVersionPrice(
                                    _vm.orderProductInfoVo.id
                                  )
                                }
                              }
                            },
                            [
                              _vm.ratingInfoVo.biaobanPayStatus === 2
                                ? _c(
                                    "a-button",
                                    {
                                      staticClass: "ml-20",
                                      attrs: { size: "small", type: "danger" }
                                    },
                                    [_vm._v("退标版费")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.orderProductInfoVo.payTime
                ? _c("div", { staticClass: "modal-items mt-20" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("支付信息")]),
                    _c("div", { staticClass: "modal-content modal-express" }, [
                      _c("div", { staticClass: "basic-item" }, [
                        _vm._v("支付时间： "),
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(
                            _vm._s(_vm.orderProductInfoVo.payTime || "未支付")
                          )
                        ]),
                        _vm.orderProductInfoVo.status == 0
                          ? _c("span", [
                              _vm._v("（倒计时"),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(_vm._s("xxxx"))
                              ]),
                              _vm._v("）")
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "basic-item" }, [
                        _vm._v("货币支付："),
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(_vm._s(_vm.orderProductInfoVo.payMoney || ""))
                        ]),
                        _vm._v("元")
                      ]),
                      _c("div", { staticClass: "basic-item" }, [
                        _vm._v(" 优惠支付："),
                        _vm.orderProductInfoVo.deductPaymentMoney
                          ? _c("span", { staticClass: "color-red" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.orderProductInfoVo.deductPaymentMoney +
                                    "元"
                                )
                              )
                            ])
                          : _c("span", { staticClass: "color-red" }, [
                              _vm._v("无")
                            ]),
                        _vm._v("， 优惠方式: "),
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(
                            _vm._s(
                              _vm.orderProductInfoVo.deductPaymentDesc || "无"
                            )
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "w-100-w",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          [1, 2, 3, 4].includes(_vm.orderProductInfoVo.status)
                            ? _c(
                                "div",
                                { staticClass: "dis-flex-start" },
                                [
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title: "确认主动退款么?",
                                        "ok-text": "Yes",
                                        "cancel-text": "No",
                                        okType: "danger"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.activeRefund(
                                            _vm.orderReturnVo.id,
                                            _vm.orderProductInfoVo.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("主动退款")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "color-red ml-20" },
                                    [
                                      _vm._v(
                                        "（一键退款，本功能无需买家退货，谨慎操作）"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "ml-20",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleShowOrderPayPopup(
                                        _vm.orderProductInfoVo.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("订单赔付")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-items mt-20" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单状态")]),
                _c("div", { staticClass: "modal-content modal-express" }, [
                  _c("div", { staticClass: "dis-flex-start-start" }, [
                    _c("div", { staticClass: "left-content dis-flex-start" }, [
                      _c("div", { staticClass: "basic-item ml-20" }, [
                        _vm._v("订单主状态： "),
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(_vm._s(_vm.orderProductInfoVo.statusName))
                        ]),
                        _vm.orderProductInfoVo.offlineStatus
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("（"),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v("线下交易")
                              ]),
                              _vm._v("）")
                            ])
                          : _vm._e(),
                        _vm.orderProductInfoVo.orderRemainTime
                          ? _c("span", [
                              _vm._v(" （"),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(_vm.orderProductInfoVo.orderRemainTime)
                                )
                              ]),
                              _vm._v("） ")
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "basic-item" },
                        [
                          _vm._v(" 送评类型： "),
                          _vm.orderProductInfoVo.orderRatingServiceRank
                            ? _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.computedRateServicePayTypeTextByDesc(
                                            _vm.orderProductInfoVo
                                              .orderRatingServiceRank
                                          )
                                        ) +
                                        "---" +
                                        _vm._s(
                                          _vm.computedRateServiceStatusMapTypeText(
                                            _vm.orderProductInfoVo.ratingStatus
                                          ) || "未发货"
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fs-20",
                                      class:
                                        _vm.dqCheckPayClassType[
                                          _vm.orderProductInfoVo
                                            .orderRatingServiceRank
                                        ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.computedRateServicePayTypeText(
                                            _vm.orderProductInfoVo
                                              .orderRatingServiceRank
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c("span", { staticClass: "color-blue" }, [
                                    _vm._v("---")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.rateServiceStatusClassType[
                                          _vm.orderProductInfoVo.ratingStatus
                                        ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.computedRateServiceStatusMapTypeText(
                                            _vm.orderProductInfoVo.ratingStatus
                                          ) || "未发货"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                2
                              )
                            : _c("span", [_vm._v("未送评")]),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定要取消评级转为直发？",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleConfirmCancelRateService(
                                    _vm.orderProductInfoVo.id
                                  )
                                }
                              }
                            },
                            [
                              _vm.orderProductInfoVo.orderRatingServiceRank
                                ? _c(
                                    "a-button",
                                    {
                                      staticClass: "ml-20",
                                      attrs: { size: "small", type: "primary" }
                                    },
                                    [_vm._v("取消评级转直发")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "right-content ml-200" }, [
                      _vm.orderProductInfoVo.refundStatusName
                        ? _c("div", { staticClass: "basic-item" }, [
                            _c("span", [
                              _vm._v("逆向子状态："),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderProductInfoVo.refundStatusName
                                  )
                                )
                              ])
                            ]),
                            _vm.orderProductInfoVo.orderReturnRemainTime
                              ? _c("span", [
                                  _vm._v(
                                    "（" +
                                      _vm._s(
                                        _vm.orderProductInfoVo
                                          .orderReturnRemainTime
                                      ) +
                                      "）"
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm.orderReturnVo.id
                        ? _c(
                            "div",
                            [
                              _c(
                                "a-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleGoToReturnOrder(
                                        _vm.orderProductInfoVo.orderNo
                                      )
                                    }
                                  }
                                },
                                [_vm._v("查看详情")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "modal-items mt-20" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品信息")]),
                _vm.productSnapshot
                  ? _c("div", { staticClass: "modal-content modal-express" }, [
                      _c("div", { staticClass: "dis-flex-start-start" }, [
                        _c(
                          "div",
                          { staticClass: "left-content" },
                          [
                            _vm._l(_vm.productSnapshot.files, function(
                              item,
                              index
                            ) {
                              return _c("img", {
                                key: index,
                                staticStyle: {
                                  "margin-right": "20px",
                                  width: "30px"
                                },
                                attrs: { mode: "widthFix", src: item, alt: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleShowBigImg(index)
                                  }
                                }
                              })
                            }),
                            _c("div", { staticClass: "basic-item mt-20" }, [
                              _vm._v(
                                "尺寸：" +
                                  _vm._s(_vm.productSnapshot.productSize)
                              )
                            ]),
                            _c("div", { staticClass: "basic-item" }, [
                              _vm._v(
                                "评级公司：" +
                                  _vm._s(
                                    _vm.productSnapshot.ratingCompanyName ||
                                      "无"
                                  )
                              )
                            ]),
                            _c("div", { staticClass: "basic-item" }, [
                              _vm._v(
                                "评级编号：" +
                                  _vm._s(
                                    _vm.productSnapshot.productCode || "无"
                                  )
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "basic-item",
                                staticStyle: { color: "orange" }
                              },
                              [
                                _vm._v(" 承诺服务： "),
                                _vm._l(
                                  _vm.productSnapshot.serviceList,
                                  function(item, index) {
                                    return _c("span", { key: index }, [
                                      _vm._v(_vm._s(item.title) + "/")
                                    ])
                                  }
                                )
                              ],
                              2
                            )
                          ],
                          2
                        ),
                        _c("div", { staticClass: "right-content ml-20" }, [
                          _c("div", [
                            _vm._v(
                              "商品名称：" +
                                _vm._s(_vm.productSnapshot.productName)
                            )
                          ]),
                          _c("div", { staticClass: "mt-20 color-red" }, [
                            _vm._v(
                              "瑕疵：" +
                                _vm._s(_vm.productSnapshot.productRemark)
                            )
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "dis-flex-start-start mt-20" }, [
                _c("div", { staticClass: "modal-items" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("span", [_vm._v("收货地址与物流")]),
                      _vm.orderLogisticsVo.consigneeRealname
                        ? _c(
                            "a-button",
                            {
                              staticClass: "change-btn",
                              attrs: { size: "small", type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeLogisticsMessage(
                                    _vm.orderLogisticsVo,
                                    _vm.orderID,
                                    "order"
                                  )
                                }
                              }
                            },
                            [_vm._v("修改发货物流")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "modal-content modal-express" }, [
                    _c("div", { staticClass: "express" }, [
                      _c("span", [
                        _vm._v(
                          "收件人：" +
                            _vm._s(_vm.orderLogisticsVo.consigneeRealname)
                        )
                      ]),
                      _c("span", { staticStyle: { "margin-left": "40px" } }, [
                        _vm._v(
                          "收件人电话：" +
                            _vm._s(_vm.orderLogisticsVo.consigneeTelphone)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "express" }, [
                      _vm._v(
                        "详细地址：" +
                          _vm._s(_vm.orderLogisticsVo.consigneeAddress)
                      )
                    ]),
                    _c("div", { staticClass: "express" }, [
                      _vm._v(
                        "发货物流：" +
                          _vm._s(_vm.orderLogisticsVo.logisticsName)
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "express",
                        on: {
                          click: function($event) {
                            return _vm.untils.copy_text(
                              _vm.orderLogisticsVo.expressNo
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "物流单号：" + _vm._s(_vm.orderLogisticsVo.expressNo)
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-items" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("span", [_vm._v("退货地址与物流")]),
                      _vm.orderReturnVo.expressNo
                        ? _c(
                            "a-button",
                            {
                              staticClass: "change-btn",
                              attrs: { size: "small", type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeLogisticsMessage(
                                    _vm.orderReturnVo,
                                    _vm.orderReturnVo.id,
                                    "returnOrder"
                                  )
                                }
                              }
                            },
                            [_vm._v("修改退货物流")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "modal-content modal-express" }, [
                    _c("div", { staticClass: "express" }, [
                      _c("span", [
                        _vm._v(
                          "收件人：" +
                            _vm._s(_vm.orderReturnVo.consigneeRealname)
                        )
                      ]),
                      _c("span", { staticStyle: { "margin-left": "40px" } }, [
                        _vm._v(
                          "收件人电话：" +
                            _vm._s(_vm.orderReturnVo.consigneeTelphone)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "express" }, [
                      _vm._v(
                        "详细地址：" +
                          _vm._s(_vm.orderReturnVo.consigneeAddress)
                      )
                    ]),
                    _c("div", { staticClass: "express" }, [
                      _vm._v(
                        "发货物流：" + _vm._s(_vm.orderReturnVo.logisticsName)
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "express",
                        on: {
                          click: function($event) {
                            return _vm.untils.copy_text(
                              _vm.orderReturnVo.expressNo
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "物流单号：" + _vm._s(_vm.orderReturnVo.expressNo)
                        )
                      ]
                    )
                  ])
                ]),
                _vm.gradeLogisticsVo
                  ? _c("div", { staticClass: "modal-items" }, [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c("span", [_vm._v("送评物流")]),
                          _c(
                            "a-button",
                            {
                              staticClass: "change-btn",
                              attrs: { size: "small", type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeLogisticsMessage(
                                    _vm.gradeLogisticsVo,
                                    _vm.orderID,
                                    "rateService"
                                  )
                                }
                              }
                            },
                            [_vm._v("修改送评物流")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "modal-content modal-express" },
                        [
                          _c("div", { staticClass: "express" }, [
                            _c("span", [
                              _vm._v(
                                "收件人：" +
                                  _vm._s(_vm.gradeLogisticsVo.consigneeRealname)
                              )
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "40px" } },
                              [
                                _vm._v(
                                  "收件人电话：" +
                                    _vm._s(
                                      _vm.gradeLogisticsVo.consigneeTelphone
                                    )
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "express" }, [
                            _vm._v(
                              "详细地址：" +
                                _vm._s(_vm.gradeLogisticsVo.consigneeAddress)
                            )
                          ]),
                          _c("div", { staticClass: "express" }, [
                            _vm._v(
                              "物流公司：" +
                                _vm._s(_vm.gradeLogisticsVo.logisticsName || "")
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "express",
                              on: {
                                click: function($event) {
                                  return _vm.untils.copy_text(
                                    _vm.gradeLogisticsVo.expressNo || ""
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "物流单号：" +
                                  _vm._s(_vm.gradeLogisticsVo.expressNo || "")
                              )
                            ]
                          ),
                          _c("div", { staticClass: "express" }, [
                            _vm._v(
                              "评级公司：" +
                                _vm._s(
                                  _vm.gradeLogisticsVo.gradeCompanyName || ""
                                )
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("备注/操作")]),
                _c(
                  "div",
                  { staticClass: "modal-content" },
                  [
                    _c("a-textarea", {
                      staticStyle: {
                        width: "400px",
                        "margin-bottom": "20px",
                        "margin-right": "20px"
                      },
                      attrs: { placeholder: "请输入备注", "auto-size": "" },
                      model: {
                        value: _vm.remarkText,
                        callback: function($$v) {
                          _vm.remarkText = $$v
                        },
                        expression: "remarkText"
                      }
                    }),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.remark(_vm.orderID)
                          }
                        }
                      },
                      [_vm._v("备注")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单日志")]),
                _c(
                  "div",
                  { staticClass: "modal-content modal-express" },
                  _vm._l(_vm.orderLogVo, function(item, index) {
                    return _c("div", { key: index, staticClass: "order-log" }, [
                      _c("div", [_vm._v(_vm._s(item.createTime))]),
                      _c("div", [_vm._v(_vm._s(item.userName))]),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            item.type == 1
                              ? "系统提示"
                              : item.type == 2
                              ? "后台操作"
                              : "备注"
                          ) +
                            "：" +
                            _vm._s(item.content || "")
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 500, centered: "", footer: null },
          model: {
            value: _vm.snapshotshow,
            callback: function($$v) {
              _vm.snapshotshow = $$v
            },
            expression: "snapshotshow"
          }
        },
        [
          _c(
            "div",
            { style: "max-height:" + _vm.ModalHeight + "px;overflow:auto;" },
            [
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("快照信息")]),
                _c("div", { staticClass: "modal-content modal-express" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("商品编号：" + _vm._s(_vm.productVersion.productNo))
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "商品名称：" +
                        _vm._s(_vm.productVersion.productName || "")
                    )
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("商品价格："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("￥" + _vm._s(_vm.productVersion.finalPrice))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("运费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("￥" + _vm._s(_vm.productVersion.logisticsFee))
                    ])
                  ]),
                  _vm.productVersion
                    ? _c(
                        "div",
                        { staticClass: "express" },
                        [
                          _c("span", [_vm._v("图片：")]),
                          _vm._l(_vm.productVersion.files, function(item, key) {
                            return _c("img", {
                              key: key,
                              staticStyle: {
                                "max-width": "50px",
                                "max-height": "50px",
                                "margin-right": "5px"
                              },
                              attrs: { src: item },
                              on: {
                                click: function($event) {
                                  return _vm.previewImg(
                                    _vm.productVersion.files
                                  )
                                }
                              }
                            })
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "basic-item" },
                    [
                      _vm._v(" 商品服务： "),
                      _vm._l(_vm.productVersion.serviceList, function(
                        tag,
                        index
                      ) {
                        return _c(
                          "a-tag",
                          { key: index, attrs: { color: "blue" } },
                          [_vm._v(" " + _vm._s(tag.title) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 900 },
          on: { ok: _vm.checkForms, cancel: _vm.cancelRefund },
          model: {
            value: _vm.showRefundModal,
            callback: function($$v) {
              _vm.showRefundModal = $$v
            },
            expression: "showRefundModal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-items" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("退货退款")]),
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.refundForms,
                      rules: _vm.refundRules,
                      "label-col": { span: 5 },
                      wrapperCol: { span: 15 }
                    }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "申请退货退款", prop: "section" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "filter-option": _vm.untils.filterOption,
                            placeholder: "选择退货原因"
                          },
                          on: { change: _vm.handleChangeRefundType },
                          model: {
                            value: _vm.refundForms.refundType,
                            callback: function($$v) {
                              _vm.$set(_vm.refundForms, "refundType", $$v)
                            },
                            expression: "refundForms.refundType"
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "buyernoreason" } },
                            [_vm._v("买家无理由[买家责任]-[扣买家]")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "productflaw" } },
                            [_vm._v("商品瑕疵[卖家责任]-[扣卖家]")]
                          ),
                          _c("a-select-option", { attrs: { value: "sham" } }, [
                            _vm._v("假货[卖家责任]-[扣卖家]")
                          ]),
                          _c(
                            "a-select-option",
                            { attrs: { value: "sellernoreason" } },
                            [_vm._v("图片与实物不符[卖家责任]-[扣卖家]")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.depositTitle } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          attrs: { name: "radioGroup", "default-value": 0 },
                          on: { change: _vm.handleChangeRadio }
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("扣除")
                          ]),
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("不扣除")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "是否扣除手续费" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          attrs: { name: "radioGroup", "default-value": 1 },
                          on: { change: _vm.handleChangeRadioByHandleFees }
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("扣除")
                          ]),
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("不扣除")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "退款类型" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          attrs: { name: "radioGroup", "default-value": -1 },
                          on: {
                            change: _vm.handleChangeRadioReturnAndRefundsType
                          }
                        },
                        [
                          _c(
                            "a-radio",
                            { attrs: { value: "goodsnotreceived" } },
                            [_vm._v("仅退款")]
                          ),
                          _c("a-radio", { attrs: { value: "goodsreceived" } }, [
                            _vm._v("退货退款")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.logisticsFeeTitle } },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "number",
                          placeholder: _vm.logisticsFeeTitle
                        },
                        on: { change: _vm.handleChangeRefundType },
                        model: {
                          value: _vm.refundForms.expressMoney,
                          callback: function($$v) {
                            _vm.$set(_vm.refundForms, "expressMoney", $$v)
                          },
                          expression: "refundForms.expressMoney"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.extraFeeTitle } },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "number",
                          placeholder: _vm.extraFeeTitle
                        },
                        on: { change: _vm.handleChangeRefundType },
                        model: {
                          value: _vm.refundForms.certificateMoney,
                          callback: function($$v) {
                            _vm.$set(_vm.refundForms, "certificateMoney", $$v)
                          },
                          expression: "refundForms.certificateMoney"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.userRateData
                    ? _c("div", [
                        _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.userRateData.couponDesc))
                        ]),
                        _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.userRateData.depositDesc))
                        ]),
                        _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.userRateData.serviceMoneyDesc))
                        ]),
                        _c("div", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.userRateData.incomePersonStr))
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "补充说明" } },
                    [
                      _c("a-input", {
                        attrs: { type: "textarea", placeholder: "补充说明" },
                        model: {
                          value: _vm.refundForms.refundReason,
                          callback: function($$v) {
                            _vm.$set(_vm.refundForms, "refundReason", $$v)
                          },
                          expression: "refundForms.refundReason"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "附件图片" } },
                    [
                      _c(
                        "a-upload",
                        {
                          key: _vm.imgKey,
                          attrs: {
                            action: _vm.ip + "/file/upload",
                            "list-type": "picture-card",
                            "file-list": _vm.picList
                          },
                          on: {
                            preview: function($event) {
                              return _vm.handlePreview()
                            },
                            change: _vm.handleChange
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("ChangeLogisticsMessage", {
        ref: "logisticsMessagePopup",
        on: {
          change: function($event) {
            return _vm.getDetail($event)
          }
        }
      }),
      _c("OrderPaidPopup", {
        ref: "orderPaidPopupEl",
        on: {
          paidOrderPrice: function($event) {
            return _vm.getDetail(_vm.orderID)
          }
        }
      }),
      _c("DqTradeDownloadExcelPopup", { ref: "dqTradeDownloadExcelPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }